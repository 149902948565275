import { useTranslation } from "react-i18next";
import yangiyol from "../../assets/images/yangiyol.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
      <div className="flex justify-center items-center px-[100px] gap-8 h-[330px]">
        <div className="w-[600px]">
          <img src={yangiyol} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Yangiyo'l tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Yangiyo'l tumani</strong> -
            1926-yil 29-sentabrda tashkil topgan bo‘lib,
            Toshkent viloyatining ma’muriy-hududiy birligi hisoblanadi.
            Yangiyo‘l tumani hududi yer maydoni 411,2 kv. km, bo‘lib, chegara
            uzunligi 164,79 km ni tashkil etadi. Tumanda 63 ta aholi punktlari,
            64 ta mahalla fuqarolar yig‘inlari va 58,8 ming ta oilalar mavjud.
            2023-yilning 1-yanvar holatiga Yangiyo‘l tumani aholisi soni
            Toshkent viloyatining doimiy aholisi (2 993,4 ming kishi)ning 2,6
            foizini yoki 196,9 ming kishini tashkil etadi. Shundan 29,2 ming
            kishi shahar joylarida va 167,7 ming kishi qishloq joylarida
            istiqomat qiladi.
          </p>
        </div>
      </div>
  );
};

export default RegionInfo;
