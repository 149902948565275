import React from "react";
import { IoClose } from "react-icons/io5";
import "./styles.css";
import { useState } from "react";
import { useGetAllPhoto } from "../../hooks/query";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { Pagination } from "@mantine/core";
import { useTranslation } from "react-i18next";

const PhotoGalery = () => {
  const { t } = useTranslation();
  const [page, setPages] = useState(1);
  const useGetAllPhotoList = useGetAllPhoto();
  const navigate = useNavigate();
  const [model, setModel] = useState(false);
  const [tempImg, setTempImg] = useState("");
  const getImg = (img) => {
    setTempImg(img);
    setModel(true);
  };

  return (
    <div className="mb-[40px]">
      <button
        onClick={() => navigate(-1)}
        className="lg:flex items-center gap-1 bg-[#01497c] text-white pr-4 px-3 rounded py-1 hover:bg-[#61a5c2] transition-all duration-200 md:flex hidden"
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      <h3 className="title text-4xl font-semibold mb-6">
        {t("Yangiyo'l tumani foto suratlarda")}
      </h3>

      <div className={model ? "model open" : "model"}>
        <img src={tempImg} alt="Oqqo'rg'on tumani foto" />
        <IoClose className="icon__x" onClick={() => setModel(false)} />
      </div>

      <div className="gallery">
        {useGetAllPhotoList.data?.results.map((item) => {
          return (
            <div
              className="pics relative overflow-hidden bg-no-repeat bg-cover "
              key={item.id}
              onClick={() => getImg(item.post_img)}
            >
              <img
                className="hover:scale-125 object-cover transition duration-700 ease-in-out"
                src={item.post_img}
                width="100%"
                height="100%"
                alt="Foto suratlar"
              />
            </div>
          );
        })}
      </div>
      <Pagination
        py={28}
        color="cyan"
        size="lg"
        radius="xl"
        page={page}
        onChange={setPages}
        disabled={page >= 10}
        total={useGetAllPhotoList.data?.total_pages}
      />
    </div>
  );
};

export default PhotoGalery;
