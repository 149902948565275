import React, { useState } from "react";
import { useGetRahbariyat } from "../../hooks/query";
import RahbariyatItem from "./RahbariyatItem";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mantine/core";

const Rahbariyat = () => {
  const { t } = useTranslation();
  const [page, setPages] = useState(1);
  const useGetRahbariyatList = useGetRahbariyat({
    page: page,
  });
  const navigate = useNavigate();

  return (
    <div className="mb-[40px] lg:pl-0 md:pl-4 pl-6">
      <button
        onClick={() => navigate(-1)}
        className="lg:flex md:flex hidden items-center gap-1 bg-[#0C356A] text-white pr-4 px-3 rounded py-1 hover:bg-[#0174BE] transition-all duration-200 "
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      <h2 className="text-center my-[20px] text-3xl font-semibold">
        {t("Rahbariyat")}
      </h2>
      {useGetRahbariyatList.data?.results.map((item) => (
        <RahbariyatItem item={item} key={item.id} />
      ))}
      <Pagination
        py={28}
        color="cyan"
        size="lg"
        radius="xl"
        page={page}
        onChange={setPages}
        disabled={page >= 10}
        total={useGetRahbariyatList.data?.total_pages}
      />
    </div>
  );
};

export default Rahbariyat;
