import React from "react";
import { useParams } from "react-router-dom";
import { useGetBudgetDetail } from "../../../../hooks/query";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { FcCalendar } from "react-icons/fc";

const BudgetDetail = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const { bgid } = useParams();
  const useGetBudgetDetailInfo = useGetBudgetDetail({
    id: bgid,
  });

  let date = new Date(useGetBudgetDetailInfo.data?.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;
  return (
    <div>
      <h4 className="text-center font-semibold text-2xl my-[20px]">
        {useGetBudgetDetailInfo.data?.title}
      </h4>
      <p className="my-8">{useGetBudgetDetailInfo.data?.text}</p>
      <div className="h-[100vh]">
        {useGetBudgetDetailInfo.data?.post_file ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={useGetBudgetDetailInfo.data?.post_file}
              plugins={[defaultLayoutPluginInstance]}
            />
          </Worker>
        ) : (
          ""
        )}
      </div>
      <p className="flex items-center gap-2 py-[20px]">
        <FcCalendar size={22} />
        {dateMDY}
      </p>
    </div>
  );
};

export default BudgetDetail;
