import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/kart.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#164863] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Yangiyo'l tumani hokimligi")}
            </h2>
          </div>
          <p className="lg:w-[600px]">
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p className="lg:w-[550px]">
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.yangiyol.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-gray-400 duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            <a
              className="hover:text-[#963f9d] duration-300"
              href="https://www.instagram.com/axborot_xizmati_77?igshid=yrbeg6ojuocd"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={26} />
            </a>
            <a
              className="hover:text-[#CD201F] duration-300"
              href="https://www.youtube.com/channel/UC2vczo-HUIpMVOjusRPbYZA"
              target="_blank"
              rel="noreferrer"
            >
              <FiYoutube size={29} />
            </a>
            <a
              className="hover:text-[#0088cc] duration-300"
              href="https://t.me/yangiyul_tuman"
              target="_blank"
              rel="noreferrer"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-[#3b5998] duration-300"
              href="https://www.facebook.com/people/%D0%AF%D0%BD%D0%B3%D0%B8%D0%B9%D1%9E%D0%BB-%D1%82%D1%83%D0%BC%D0%B0%D0%BD%D0%B8-%D2%B3%D0%BE%D0%BA%D0%B8%D0%BC%D0%BB%D0%B8%D0%B3%D0%B8/100066940431125/"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Gulbahor shaharchasi, Sh.Rashidov ko'chasi ")}</p>
          <a
            href="https://www.google.com/maps/place/Yangiyo'l+tuman+Hokimiyati/@41.0730404,69.0232194,16z/data=!4m10!1m2!2m1!1syangiyo'l+tumani+hokimligi!3m6!1s0x38ae7bda21e60db1:0x1ded1b149d47ea87!8m2!3d41.0725657!4d69.0256359!15sChp5YW5naXlvJ2wgdHVtYW5pIGhva2ltbGlnaZIBEWdvdmVybm1lbnRfb2ZmaWNl4AEA!16s%2Fg%2F11h27zn379?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              a
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 70 202 10 25")} <br />
          </p>
          <p>
            {t("Tel: +998 70 202 10 44")}
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <div className="lg:block md:block hidden">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Footer;
